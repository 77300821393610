<template>
  <div class="bg-white dark:bg-gray-800 min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
    <div class="max-w-max mx-auto">
      <main class="sm:flex">
        <div class="">
          <div class="">
            <h1 class="dark:text-white text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Panier vide</h1>
            <p class="mt-1 dark:text-indigo-200 text-base text-gray-500">Commencer par ajouter des produits à votre panier.</p>
          </div>
          <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent ">
            <nuxt-link :to="{name: 'product-catalog'}" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Catalogue de produits </nuxt-link>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
